import {
  useAutocompleteAddress,
  useAutocompleteAddressDetails,
} from '@emico-hooks/postcode-eu'
import { ButtonUnstyled } from '@emico-react/buttons'
import styled from '@emotion/styled'
import { t } from '@lingui/macro'
import React, { useEffect, useState } from 'react'
import {
  Control,
  FieldValues,
  Path,
  PathValue,
  UseFormRegister,
  UseFormSetFocus,
  UseFormSetValue,
  useWatch,
} from 'react-hook-form'
import { v4 as uuidV4 } from 'uuid'

import ChevronRightIcon from '../icons/ChevronRightIcon'
import CrossIcon from '../icons/CrossIcon'
import theme from '../theme'
import Alert from './Alert'
import Input from './Input'

const StyledAlert = styled(Alert)`
  margin: ${theme.spacing.sm} 0;
`

const FormFieldWrapper = styled.div`
  position: relative;
`

const List = styled.ul`
  margin: 0;
  padding: 0;
  position: absolute;
  width: 100%;
  border: ${theme.borders.default};
  border-top-width: 0;
  list-style: none;
  z-index: 1;
  width: 100%;
`

const ListItem = styled.li`
  display: flex;
`

const MatchButton = styled(ButtonUnstyled)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: ${theme.spacing.sm} ${theme.spacing.sm};
  background-color: ${theme.colors.backgroundLight};
  transition-property: ${theme.transition.properties.colors};
  transition-duration: ${theme.transition.durations.normal};
  transition-timing-function: ${theme.transition.timingFunctions
    .cubicBezierSmooth};

  &:hover,
  &:focus {
    background-color: ${theme.colors.grayMiddle};
  }
`

interface AutocompleteAddressResultMatch {
  context: string
  description: string
  highlights: number[][]
  label: string
  precision: string
  value: string
}

export interface AutocompleteAddressDetails {
  address: {
    building: string
    buildingNumber?: number | null
    buildingNumberAddition?: string | null
    postcode: string
    street: string
    locality: string
    country: string
  }
}

enum AutocompleteAddressLanguages {
  NL = 'NL',
  DE = 'DE',
  EN = 'EN',
}

enum AutocompleteAddressMatchType {
  ADDRESS = 'Address',
  CITY = 'City',
  STREET = 'Street',
}

function getLanguage(language: string) {
  switch (language) {
    case 'nl':
      return AutocompleteAddressLanguages.NL
    case 'de':
      return AutocompleteAddressLanguages.DE
    default:
      return AutocompleteAddressLanguages.EN
  }
}

function getCountryContext(countryCode: string) {
  switch (countryCode) {
    case 'AT':
      return 'AUT'
    case 'BE':
      return 'BEL'
    case 'DK':
      return 'DNK'
    case 'FR':
      return 'FRA'
    case 'DE':
      return 'DEU'
    case 'GB':
      return 'GBR'
    default:
      return 'NLD'
  }
}

interface Props<T extends FieldValues> {
  register: UseFormRegister<T>
  control: Control<T>
  setValue: UseFormSetValue<T>
  setFocus: UseFormSetFocus<T>
  countryValue: string
  handleStateChange: (details: AutocompleteAddressDetails | undefined) => void
  registerName?: string
  hasAutoFocus?: boolean
}

const FormFieldsAddressAutocomplete = <T extends FieldValues>({
  register,
  control,
  setValue,
  setFocus,
  countryValue,
  registerName = 'autocompleteAddress',
  hasAutoFocus = false,
  handleStateChange,
}: Props<T>) => {
  const [uuid, setUuid] = useState<string>(uuidV4())
  const [isSessionCompleted, setIsSessionCompleted] = useState<boolean>(false)

  const language = getLanguage(countryValue)
  const countryContext = getCountryContext(countryValue)

  const [
    selectedAutocompleteAddressMatch,
    setSelectedAutocompleteAddressMatch,
  ] = useState<AutocompleteAddressResultMatch | undefined>(undefined)

  const [
    autocompleteAddressPrecisionMatch,
    setAutocompleteAddressPrecisionMatch,
  ] = useState<
    Pick<AutocompleteAddressResultMatch, 'context' | 'value'> | undefined
  >(undefined)

  const [selectedAddressDetails, setSelectedAddressDetails] = useState<
    AutocompleteAddressDetails | undefined
  >(undefined)

  const autocompleteAddressValue = useWatch({
    control,
    name: registerName as Path<T>,
  })

  const { data: addressData, error: addressError } = useAutocompleteAddress(
    uuid,
    selectedAutocompleteAddressMatch?.context ?? countryContext,
    selectedAutocompleteAddressMatch?.value ?? autocompleteAddressValue,
    language,
    {
      skip: !autocompleteAddressValue || !uuid || isSessionCompleted,
    },
  )

  const { data: addressDetailsData, error: detailsError } =
    useAutocompleteAddressDetails(
      uuid,
      autocompleteAddressPrecisionMatch?.context,
      {
        skip:
          !autocompleteAddressPrecisionMatch?.context ||
          !uuid ||
          isSessionCompleted,
      },
    )

  const autocompleteAddressMatches = addressData?.matches
  const autocompleteAddressMatchCount = autocompleteAddressMatches?.length ?? 0
  const hasPrecisionResult =
    Boolean(addressDetailsData) &&
    autocompleteAddressMatchCount === 1 &&
    autocompleteAddressMatches?.[0].value === autocompleteAddressValue

  /**
   * Pass address details data to parent component
   */
  useEffect(() => {
    handleStateChange(selectedAddressDetails ?? undefined)
  }, [selectedAddressDetails, handleStateChange])

  /**
   * Remove autocomplete address context to start a 'clean' search
   * Context should be removed each time the address field is updated
   */
  useEffect(() => {
    setSelectedAutocompleteAddressMatch(undefined)
  }, [autocompleteAddressValue])

  /**
   * Save address details data in state & set session as completed
   * A session ends when a precision match (selected full address) is added/updated and address detail data is retrieved
   */
  useEffect(() => {
    if (addressDetailsData) {
      setSelectedAddressDetails(addressDetailsData)
      setIsSessionCompleted(true)
    }
  }, [addressDetailsData, autocompleteAddressPrecisionMatch])

  /**
   * Create and set new session token when new session is starting
   * A session starts when the user updates the address field and no UUID is available yet
   */
  useEffect(() => {
    if (
      (!uuid || isSessionCompleted) &&
      autocompleteAddressPrecisionMatch?.value !== autocompleteAddressValue
    ) {
      setAutocompleteAddressPrecisionMatch(undefined)
      setUuid(uuidV4())
      setIsSessionCompleted(false)
    }
  }, [
    autocompleteAddressValue,
    autocompleteAddressPrecisionMatch?.value,
    uuid,
    isSessionCompleted,
  ])

  return (
    <FormFieldWrapper>
      <Input
        control={control}
        label={t({
          message: 'Search address',
        })}
        {...register(registerName as Path<T>)}
        autoFocus={hasAutoFocus}
        append={
          autocompleteAddressValue && (
            <ButtonUnstyled
              analyticsContext="formFieldsAddressAutocomplete"
              analyticsName="clear"
              onPress={() => {
                setValue(registerName as Path<T>, '' as PathValue<T, Path<T>>)
                setFocus(registerName as Path<T>)
              }}
            >
              <CrossIcon />
            </ButtonUnstyled>
          )
        }
      />

      {addressError?.message && (
        <StyledAlert type="error">{addressError.message}</StyledAlert>
      )}

      {detailsError?.message && (
        <StyledAlert type="error">{detailsError.message}</StyledAlert>
      )}

      {!hasPrecisionResult && autocompleteAddressMatchCount > 0 && (
        <List>
          {autocompleteAddressMatches?.map((match, index) => (
            <ListItem key={index} value={match.value}>
              <MatchButton
                analyticsContext="formFieldsAddressAutocomplete"
                analyticsName="autocompleteAddressMatch"
                onPress={() => {
                  setFocus(registerName as Path<T>)
                  setSelectedAutocompleteAddressMatch(match)

                  if (
                    match.precision === AutocompleteAddressMatchType.ADDRESS
                  ) {
                    setValue(
                      registerName as Path<T>,
                      match.value as PathValue<T, Path<T>>,
                    )

                    setAutocompleteAddressPrecisionMatch({
                      value: match.value,
                      context: match.context,
                    })
                  }
                }}
              >
                <span>
                  {match.label} {match.description}
                </span>

                {match.precision !== AutocompleteAddressMatchType.ADDRESS && (
                  <ChevronRightIcon />
                )}
              </MatchButton>
            </ListItem>
          ))}
        </List>
      )}
    </FormFieldWrapper>
  )
}

export default FormFieldsAddressAutocomplete
